import React from "react";
import koi1 from "../../Assets/imgs/koi1.png";
import koi2 from "../../Assets/imgs/koi2.png";
import koi3 from "../../Assets/imgs/koi3.png";
import Fade from "react-reveal/Fade";

import {
  ProjectSection,
  Row,
  ImageWrapper,
  Img,
} from "../PSAF/ProjectElements";

function Project() {
  return (
    <>
      <ProjectSection>
        <Fade bottom big>
          <div>
            <Row>
              <ImageWrapper>
                <Img src={koi1} />
              </ImageWrapper>
            </Row>
          </div>
        </Fade>
        <Fade bottom big>
          <div>
            <Row>
              <ImageWrapper>
                <Img src={koi2} />
              </ImageWrapper>
            </Row>
          </div>
        </Fade>
        <Fade bottom big>
          <div>
            <Row>
              <ImageWrapper>
                <Img src={koi3} />
              </ImageWrapper>
            </Row>
          </div>
        </Fade>
      </ProjectSection>
    </>
  );
}

export default Project;
