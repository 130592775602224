import React from "react";
import ceria1 from "../../Assets/imgs/ceria1.png";
import ceria2 from "../../Assets/imgs/ceria2.png";
import ceria3 from "../../Assets/imgs/ceria3.png";
import Fade from "react-reveal/Fade";

import {
  ProjectSection,
  Row,
  ImageWrapper,
  Img,
} from "../PSAF/ProjectElements";

function Project() {
  return (
    <>
      <ProjectSection>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={ceria1} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={ceria2} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={ceria3} />
            </ImageWrapper>
          </Fade>
        </Row>
      </ProjectSection>
    </>
  );
}

export default Project;
