export const HeroData = {
  lineHeading: "NEWTAB.ID",
  heading: "Hello, Welcome to Newtab!",
  description:
    "New Tab is a team of website developer based in Jakarta and Bandung, Indonesia. The quality of your website is our priority. We make your website looks aesthetically pleasing while keeping its complex functionalliy.",
  img: require("../../Assets/imgs/home.png").default,
};

export const ServicesData = {
  lineHeading: "WHAT WE DO",
  uxDescription:
    "UX design provides meaningful and relevant experiences to users. We are able to create a well structured flow to keep user exploring your product.",
  uiDescription:
    "UI design brings together concept from integration design, visual design, and information architecture. Your product will look aesthetically pleasing while keeping its complex functionality.",
  webDevDescription:
    "Web Dev delivers various type of website ranging from a simple single static page of plain text to complex Web-based internet applications, electronic businesses, and social network services.",
};

export const ClientsData = {
  lineHeading: "OUR PREVIOUS CLIENTS",
};

export const PortfolioData = {
  lineHeading: "OUR PORTFOLIO",
};
