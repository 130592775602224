import React from 'react';
import GlobalStyle from './GlobalStyles';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ToTop from './Utils/ToTop';

import Landing from './components/Pages/Landing';
import Psaf from './components/Pages/Psaf';
import Koi from './components/Pages/Koi';
import Technoskill from './components/Pages/Technoskill';
import HN from './components/Pages/HN';
import Cens from './components/Pages/Cens';
import Ceria from './components/Pages/Ceria';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <Router>
      <ToTop>
      <GlobalStyle />
      <Switch>
        <Route path='/' component={Landing} exact />
        <Route path='/home' component={Landing} exact />
        <Route path='/home/project' component={Landing} exact />
        <Route path='/home/portfolio' component={Landing} exact />
        <Route path='/project/psaf' component={Psaf} exact />
        <Route path='/project/koi' component={Koi} exact />
        <Route path='/project/piptek' component={Technoskill} exact />
        <Route path='/project/hn' component={HN} exact />
        <Route path='/project/cens' component={Cens} exact />
        <Route path='/project/ceria' component={Ceria} exact />
      </Switch>
      <Footer />
      </ToTop>
    </Router>
  );
}

export default App;
