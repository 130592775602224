import React, { useState, useEffect } from "react";
import icon from "../../components/Assets/imgs/newtab.png";
import { FaTimes, FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Jump from "react-reveal/Jump";

import {
  Nav,
  NavContainer,
  Logo,
  Icon,
  MobileIcon,
  NavMenu,
  NavItem,
  NavButton,
  ButtonLink,
  Button,
  LinkScroll,
} from "./NavbarElements";
// import { Contact } from '../../Utils/Modal/Contact';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const [button, setButton] = useState(true);
  // const [showModal, setShowModal] = useState(false);

  const handleToggle = () => setToggle(!toggle);
  const closeMenu = () => setToggle(false);
  // const openModal = () => {
  //     setShowModal(prev => !prev);
  // }

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  const [scrollNav, setScrollnav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollnav(true);
    } else {
      setScrollnav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <IconContext.Provider value={{ color: "#6358F8" }}>
      <Nav scrollNav={scrollNav}>
        <NavContainer>
          <Logo to="/home" onClick={closeMenu}>
            <Icon src={icon} width="50px" height="40px" />
          </Logo>
          <MobileIcon onClick={handleToggle}>
            {toggle ? <FaTimes /> : <FaBars />}
          </MobileIcon>
          <NavMenu onClick={handleToggle} toggle={toggle}>
            <NavItem>
              <LinkScroll to="/home" smooth onClick={closeMenu}>
                HOME
              </LinkScroll>
            </NavItem>
            <NavItem>
              <LinkScroll to="/home/project" smooth onClick={closeMenu}>
                PROJECT
              </LinkScroll>
            </NavItem>
            <NavItem>
              <LinkScroll to="/home/portfolio" smooth onClick={closeMenu}>
                PORTFOLIO
              </LinkScroll>
            </NavItem>
            <Jump>
              <NavButton>
                {button ? (
                  <ButtonLink
                    href="https://api.whatsapp.com/send/?phone=6281395098451&text=Halo+newtab!&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button primary>CONTACT US</Button>
                  </ButtonLink>
                ) : (
                  <ButtonLink
                    href="https://api.whatsapp.com/send/?phone=6281395098451&text=Halo+newtab!&app_absent=0"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button onClick={closeMenu} big primary>
                      CONTACT US
                    </Button>
                  </ButtonLink>
                )}
              </NavButton>
            </Jump>
          </NavMenu>
        </NavContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar;
