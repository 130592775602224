import React, { useState } from "react";
import styled from "styled-components";
import bg from "../../Assets/imgs/hn_bg.png";

import {
  HeroContent,
  Container,
  Blur,
  Wrapper,
  Title,
  Paragraph,
  ButtonWrapper,
  Arrow,
  ArrowRight,
} from "../PSAF/HeroElements";

import { Button } from "../../../GlobalStyles";

import Fade from "react-reveal/Fade";

const HeroSection = styled.section`
  background: url(${bg});
  height: 100vh;
  background-position: center;
  background-size: cover;

  z-index: 1;
  width: 100%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 50px;
  margin-top: -80px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
  }
`;

function Hero({ title, subtitle }) {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <HeroSection>
        <Container>
          <HeroContent>
            <Blur>
              <Wrapper>
                <Fade left big cascade>
                  <Title>{title}</Title>
                  <Paragraph>{subtitle}</Paragraph>
                  <ButtonWrapper>
                    <Button
                      href="https://www.himawannurcahyanto.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                    >
                      VISIT WEBSITE{hover ? <Arrow /> : <ArrowRight />}
                    </Button>
                  </ButtonWrapper>
                </Fade>
              </Wrapper>
            </Blur>
          </HeroContent>
        </Container>
      </HeroSection>
    </>
  );
}

export default Hero;
