import React from 'react'

import Hero from './Ceria/Hero';
import { HeroData } from './Ceria/Data'
import Project from './Ceria/Project';
import Navbar from '../Navbar/NavbarOut';

function Ceria() {
    return (
        <>
            <Navbar />
            <Hero {...HeroData} />
            <Project />
        </>
    )
}

export default Ceria
