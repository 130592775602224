import React from 'react';

import { HeroData, ServicesData, ClientsData, PortfolioData } from './Landing/Data';
import Navbar from '../Navbar/Navbar';
import Hero from './Landing/Hero';
import Services from './Landing/Services';
import Clients from './Landing/Clients';
import Portfolio from './Landing/Portfolio';

const Landing = () => {
    return (
        <>
            <Navbar />
            <Hero {...HeroData} />
            <Services {...ServicesData} />
            <Clients {...ClientsData} />
            <Portfolio {...PortfolioData} />
        </>
    )
}

export default Landing
