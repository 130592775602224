import React from 'react'

import Hero from './Technoskill/Hero';
import Project from './Technoskill/Project';

import {
    HeroData
} from './Technoskill/Data'
import Navbar from '../Navbar/NavbarOut';

function Technoskill() {
    return (
        <div>
            <Navbar />
            <Hero {...HeroData} />
            <Project />
        </div>
    )
}

export default Technoskill
