import React from "react";
// import { Link } from 'react-router-dom';
// import character from '../../Assets/imgs/svg-1.svg';

import {
  HeroSection,
  Row,
  Column,
  TextWrapper,
  Line,
  Heading,
  Paragraph,
  ImageWrapper,
  Img,
} from "./HeroElements";

import { Container } from "../../../GlobalStyles";
import Fade from "react-reveal/Fade";

function Hero({ lineHeading, heading, description, img }) {
  return (
    <>
      <HeroSection id="/home">
        <Container>
          <Row>
            <Column>
              <TextWrapper>
                <Fade left big cascade>
                  <div>
                    <Line>{lineHeading}</Line>
                  </div>
                  <div>
                    <Heading>{heading}</Heading>
                  </div>
                  <div>
                    <Paragraph>{description}</Paragraph>
                  </div>
                </Fade>
              </TextWrapper>
            </Column>
            <Column>
              <Fade right big>
                <ImageWrapper>
                  <Img src={img} alt="home.png" />
                </ImageWrapper>
              </Fade>
            </Column>
          </Row>
        </Container>
      </HeroSection>
    </>
  );
}

export default Hero;
