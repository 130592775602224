import React from "react";

import techno1 from "../../Assets/imgs/techno1.png";
import techno2 from "../../Assets/imgs/techno2.png";
import techno3 from "../../Assets/imgs/techno3.png";
import Fade from "react-reveal/Fade";

import {
  ProjectSection,
  Row,
  ImageWrapper,
  Img,
} from "../PSAF/ProjectElements";

function Project() {
  return (
    <>
      <ProjectSection>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={techno1} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={techno2} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={techno3} />
            </ImageWrapper>
          </Fade>
        </Row>
      </ProjectSection>
    </>
  );
}

export default Project;
