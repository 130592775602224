import React from "react";
import cens1 from "../../Assets/imgs/cens1.png";
import cens2 from "../../Assets/imgs/cens2.png";
import cens3 from "../../Assets/imgs/cens3.png";
import cens4 from "../../Assets/imgs/cens4.png";
import Fade from "react-reveal/Fade";
import {
  ProjectSection,
  Row,
  ImageWrapper,
  Img,
} from "../PSAF/ProjectElements";

function Project() {
  return (
    <>
      <ProjectSection>
        <Fade bottom big cascade>
          <Row>
            <ImageWrapper>
              <Img src={cens1} />
            </ImageWrapper>
          </Row>{" "}
        </Fade>
        <Fade bottom big cascade>
          <Row>
            <ImageWrapper>
              <Img src={cens2} />
            </ImageWrapper>
          </Row>
        </Fade>
        <Fade bottom big cascade>
          <Row>
            <ImageWrapper>
              <Img src={cens3} />
            </ImageWrapper>
          </Row>
        </Fade>
        <Fade bottom big cascade>
          <Row>
            <ImageWrapper>
              <Img src={cens4} />
            </ImageWrapper>
          </Row>
        </Fade>
      </ProjectSection>
    </>
  );
}

export default Project;
