import styled from 'styled-components';
import bg from '../../Assets/imgs/psaf_bg.png';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const HeroContainer = styled.div`
    background: url(${bg});
    height: 100vh;
    background-position: center;
    background-size: cover;

    max-width: 1350px;
    z-index: 1;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 50px;
    margin-top: -80px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
  }
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  padding-right: 50px;
`;

export const HeroContent = styled.div`
    height: calc(100vh - 80px);
    max-height: 100%;
    width: 100vw;
    padding: 0rem;
`;

export const Blur = styled.div`
    height: 100vh;
    display: flex;
    width: 55%;
    background: rgba(255, 255, 255, 0.75);

    @media screen and (max-width: 768px) {
        flex-basis: 100%;
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    max-height: 0 2rem;
    width: 600px;
    margin-top: 20px;
    margin-left: 70px;

    font-family: 'Poppins';
    font-weight: bold;
    color: #000;
    
    @media screen and (max-width: 650px) {
        width: 100%;
        margin-left: 30px;
    }
`;

export const ButtonWrap = styled.button`
    font-size: 1.4rem;
    padding: 1rem 4rem;
    border: none;
    background: #6358F8;
    color: #fff;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #0467FB;
        cursor: pointer;
    }
`;

export const Title = styled.h1`
    font-size: clamp(30px, 10vw, 50px);
    margin-bottom: 1rem;
    letter-spacing: 5px;
`;

export const Paragraph = styled.p`
    font-size: clamp(16px, 1.5vw, 18px);
    margin-bottom: 2rem;
    letter-spacing: 0.06em;
    text-align: justify;
    font-weight: 400;
    line-height: 30px;
    width: 85%;

    @media screen and (max-width: 768px) {
        width: 90%;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Arrow = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;