import React from 'react'
import { FaWhatsapp, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

import {
    FooterSection,
    Container,
    FooterTextLeft,
    FooterIcons,
} from './FooterElements';

const Footer = () => {
    return (
        <>
        <IconContext.Provider value={{ color: '#fff' }}>
            <FooterSection id='/contact'>
                <Container>
                    <FooterTextLeft>Copyright 2021 ©Newtab</FooterTextLeft>
                    <FooterIcons>
                        <a href='https://www.linkedin.com/company/newtab-id/' target="_blank" rel="noopener noreferrer">
                            <FaLinkedin />
                        </a>
                        <a href='https://api.whatsapp.com/send/?phone=6281395098451&text=Halo%21+nama+saya+...&app_absent=0' target="_blank" rel="noopener noreferrer">
                            <FaWhatsapp />
                        </a>
                        <a href='https://www.instagram.com/newtab.id/' target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                    </FooterIcons>
                </Container>
            </FooterSection>
        </IconContext.Provider>
        </>
    );
};

export default Footer;