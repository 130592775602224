import React from "react";
import hn1 from "../../Assets/imgs/hn1.png";
import hn2 from "../../Assets/imgs/hn2.png";
import hn3 from "../../Assets/imgs/hn3.png";
import Fade from "react-reveal/Fade";

import {
  ProjectSection,
  Row,
  ImageWrapper,
  Img,
} from "../PSAF/ProjectElements";

function Project() {
  return (
    <>
      <ProjectSection>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={hn1} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={hn2} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big>
            <ImageWrapper>
              <Img src={hn3} />
            </ImageWrapper>
          </Fade>
        </Row>
      </ProjectSection>
    </>
  );
}

export default Project;
