import React from 'react';

import Hero from './Koi/Hero';
import { HeroData } from './Koi/Data';
import Navbar from '../Navbar/NavbarOut';
import Project from './Koi/Project';

function Koi() {
    return (
        <>
            <Navbar />
            <Hero {...HeroData} />
            <Project />
        </>
    )
}

export default Koi
