import React from "react";
import icon1 from "../../Assets/imgs/censui card.png";
import icon2 from "../../Assets/imgs/lelang koi card.png";
import icon3 from "../../Assets/imgs/psaf.png";
import icon4 from "../../Assets/imgs/piptek.png";
import icon5 from "../../Assets/imgs/hn card.png";
import icon6 from "../../Assets/imgs/ceria.png";

import { Row, Column, TextWrapper, Line } from "./HeroElements";

import {
  PortfolioSection,
  GridRow,
  PortfolioWrapper,
  PortfolioCard,
  PortfolioContainer,
  PortfolioImage,
  PortfolioDetails,
  PortfolioTitle,
  PortfolioLink,
} from "./PortfolioElements";

import { Container } from "../../../GlobalStyles";
import Fade from "react-reveal/Fade";

const Portfolio = ({ lineHeading }) => {
  return (
    <>
      <PortfolioSection id="/home/portfolio">
        <Container>
          <Row>
            <Column>
              <TextWrapper>
                <Line>{lineHeading}</Line>
              </TextWrapper>
            </Column>
          </Row>
          <GridRow>
            <Fade bottom big>
              <PortfolioWrapper>
                <PortfolioCard to="/project/cens">
                  <PortfolioContainer>
                    <PortfolioImage src={icon1} />
                    <PortfolioDetails style={{ backgroundColor: "#000040" }}>
                      <PortfolioTitle>CENS UI</PortfolioTitle>
                      <PortfolioLink to="/project">view more</PortfolioLink>
                    </PortfolioDetails>
                  </PortfolioContainer>
                </PortfolioCard>

                <PortfolioCard to="/project/koi">
                  <PortfolioContainer>
                    <PortfolioImage src={icon2} />
                    <PortfolioDetails style={{ backgroundColor: "#FD7001" }}>
                      <PortfolioTitle>Lelang KOI</PortfolioTitle>
                      <PortfolioLink to="/project">view more</PortfolioLink>
                    </PortfolioDetails>
                  </PortfolioContainer>
                </PortfolioCard>

                <PortfolioCard to="/project/psaf">
                  <PortfolioContainer>
                    <PortfolioImage src={icon3} />
                    <PortfolioDetails style={{ backgroundColor: "#000040" }}>
                      <PortfolioTitle>PSAF UI</PortfolioTitle>
                      <PortfolioLink>view more</PortfolioLink>
                    </PortfolioDetails>
                  </PortfolioContainer>
                </PortfolioCard>
                <PortfolioCard to="/project/piptek">
                  <PortfolioContainer>
                    <PortfolioImage src={icon4} />
                    <PortfolioDetails style={{ backgroundColor: "#FD7001" }}>
                      <PortfolioTitle>PIPTEK IME</PortfolioTitle>
                      <PortfolioLink>view more</PortfolioLink>
                    </PortfolioDetails>
                  </PortfolioContainer>
                </PortfolioCard>
                <PortfolioCard to="/project/hn">
                  <PortfolioContainer>
                    <PortfolioImage src={icon5} />
                    <PortfolioDetails style={{ backgroundColor: "#000040" }}>
                      <PortfolioTitle>HN</PortfolioTitle>
                      <PortfolioLink
                        href="https://www.himawannurcahyanto.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        view more
                      </PortfolioLink>
                    </PortfolioDetails>
                  </PortfolioContainer>
                </PortfolioCard>
                <PortfolioCard to="/project/ceria">
                  <PortfolioContainer>
                    <PortfolioImage src={icon6} />
                    <PortfolioDetails style={{ backgroundColor: "#FD7001" }}>
                      <PortfolioTitle>CERIA</PortfolioTitle>
                      <PortfolioLink>view more</PortfolioLink>
                    </PortfolioDetails>
                  </PortfolioContainer>
                </PortfolioCard>
              </PortfolioWrapper>
            </Fade>
          </GridRow>
        </Container>
      </PortfolioSection>
    </>
  );
};

export default Portfolio;
