import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PortfolioSection = styled.section`
    color: #fff;
    padding: 50px 0;
    background: white;
`;

export const GridRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
`;

export const PortfolioWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 20px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px; 
    }
`;

export const PortfolioCard = styled(Link)`
    width: 432px;
    height: 615px;
    background: #fff;
    position: relative;
    overflow: hidden;
    margin: auto;
    transform: scale(0.95);
    border-radius: 40px 40px 40px 40px;
    transition: box-shadow 0.5s, transform 0.5s;
    text-decoration: none;

    &:hover {
        transform: scale(1);
        box-shadow: 5px 20px 30px rgba(0,0,0,0.2);
    }

    @media screen and (max-width: 480px) {
        width: 362px;
        height: 525px;
    }
`;

export const PortfolioContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const PortfolioImage = styled.img`
    width: 100%;
    height: 70%;
`;

export const PortfolioDetails = styled.div`
    width: 100%;
    height: 30%;
    transition: transform 0.5s;
    position: relative;
    text-align: center;
    padding: 20px;
`;

export const PortfolioTitle = styled.h1`
    color: #fff;

    font-size: clamp(30px, 10vw, 55px);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;

    @media screen and (max-width: 480px) {
        font-size: 3rem;
    }
`;

export const PortfolioLink = styled.a`
    color: #fff;
    position: relative;
    top: 5px;
    text-decoration: none;
    cursor: pointer;

    font-family: 'Poppins';
    letter-spacing: 0.08em;
    font-size: clamp(15px, 10vw, 20px);
    font-weight: 500;

    &:hover {
        font-weight: bold;
        transition: 0.2s ease-in-out;
    }

    @media screen and (max-width: 480px) {
        top: 5px;
        font-size: 1rem;
    }
`;
