import React from "react";
import psaf1 from "../../Assets/imgs/psaf1.png";
import psaf2 from "../../Assets/imgs/psaf2.png";
import psaf3 from "../../Assets/imgs/psaf3.png";
import Fade from "react-reveal/Fade";

import { ProjectSection, Row, ImageWrapper, Img } from "./ProjectElements";

function Project() {
  return (
    <>
      <ProjectSection>
        <Row>
          <Fade bottom big cascade>
            <ImageWrapper>
              <Img src={psaf1} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big cascade>
            <ImageWrapper>
              <Img src={psaf2} />
            </ImageWrapper>
          </Fade>
        </Row>
        <Row>
          <Fade bottom big cascade>
            <ImageWrapper>
              <Img src={psaf3} />
            </ImageWrapper>
          </Fade>
        </Row>
      </ProjectSection>
    </>
  );
}

export default Project;
