import React from "react";
import icon1 from "../../Assets/imgs/joystick.png";
import icon2 from "../../Assets/imgs/roller.png";
import icon3 from "../../Assets/imgs/pc.png";

import { Container } from "../../../GlobalStyles";

import { Row, Column, TextWrapper, Line } from "./HeroElements";

import {
  ServicesSection,
  GridRow,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesSubtitle,
  ServicesContent,
} from "./ServicesElements";
import Fade from "react-reveal/Fade";

const Services = ({
  lineHeading,
  uiDescription,
  uxDescription,
  webDevDescription,
}) => {
  return (
    <>
      <ServicesSection>
        <Container>
          <Row>
            <Column>
              <TextWrapper>
                <Line>{lineHeading}</Line>
              </TextWrapper>
            </Column>
          </Row>
          <GridRow>
            <ServicesWrapper>
              <Fade left big>
                <ServicesCard>
                  <ServicesIcon src={icon1} />
                  <ServicesSubtitle>User Experience (UX)</ServicesSubtitle>
                  <ServicesContent>{uxDescription}</ServicesContent>
                </ServicesCard>
              </Fade>
              <Fade up big>
                <ServicesCard>
                  <ServicesIcon src={icon2} />
                  <ServicesSubtitle>User Interface (UI)</ServicesSubtitle>
                  <ServicesContent>{uiDescription}</ServicesContent>
                </ServicesCard>
              </Fade>

              <Fade right big>
                <ServicesCard>
                  <ServicesIcon src={icon3} />
                  <ServicesSubtitle>Web Development</ServicesSubtitle>
                  <ServicesContent>{webDevDescription}</ServicesContent>
                </ServicesCard>
              </Fade>
            </ServicesWrapper>
          </GridRow>
        </Container>
      </ServicesSection>
    </>
  );
};

export default Services;
