import React from "react";
import Hero from "./Cens/Hero";

import { HeroData } from "./Cens/Data";
import Project from "./Cens/Project";
import Navbar from "../Navbar/NavbarOut";
import config from "react-reveal/globals";

config({ ssrFadeout: true, ssrReveal: true });

function Cens() {
  return (
    <>
      <Navbar />
      <Hero {...HeroData} />
      <Project />
    </>
  );
}

export default Cens;
