import React, { useState } from "react";

import {
  HeroContainer,
  Container,
  HeroContent,
  Blur,
  Wrapper,
  Title,
  Paragraph,
  ButtonWrapper,
  Arrow,
  ArrowRight,
} from "./HeroElements";

import { Button } from "../../../GlobalStyles";
import Fade from "react-reveal/Fade";

function Psaf({ title, subtitle }) {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <HeroContainer>
        <Container>
          <HeroContent>
            <Blur>
              <Wrapper>
                {" "}
                <Fade left big cascade>
                  <Title>{title}</Title>
                  <Paragraph>{subtitle}</Paragraph>
                  <ButtonWrapper>
                    <Button
                      href="https://www.psafmipaui.id/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                    >
                      VISIT WEBSITE{hover ? <Arrow /> : <ArrowRight />}
                    </Button>
                  </ButtonWrapper>
                </Fade>
              </Wrapper>
            </Blur>
          </HeroContent>
        </Container>
      </HeroContainer>
    </>
  );
}

export default Psaf;
