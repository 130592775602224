import styled from "styled-components";

export const ProjectSection = styled.section`
  color: #fff;
  padding: 60px 0;
  background: white;
`;

export const Row = styled.div`
  display: flex;
  margin: 0 -15px -15px -15px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 50px;
`;

export const Column = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    padding-bottom: 65px;
  }
`;

export const Title = styled.h1`
  font-family: Poppins;
  letter-spacing: 5px;
  cursor: default;
  font-size: 1.5rem;
  color: #000;
`;

export const Paragraph = styled.p`
  max-width: 440px;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  font-family: Poppins;
  cursor: default;
  text-align: justify;
`;

export const ImageWrapper = styled.div`
  max-width: 800px;
  // min-height: 500px;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    /* min-height: 0px; */
    max-width: 100%;
    /* height: auto; */
    max-height: 350px;
  }
`;

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  width: 100%;
  height: auto;
  align-self: flex-start;
  min-height: auto;
`;
