import React from 'react';
import Hero from './PSAF/Hero';

import { HeroData } from './PSAF/Data';

import Project from './PSAF/Project';
import Navbar from '../Navbar/NavbarOut';

const Psaf = () => {
    return (
        <>
            <Navbar />
            <Hero {...HeroData} />
            <Project />
        </>
    )
}

export default Psaf
