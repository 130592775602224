import React, { useState } from "react";
import styled from "styled-components";

import bg from "../../Assets/imgs/cens_bg.png";

import {
  HeroContent,
  ButtonWrapper,
  Arrow,
  ArrowRight,
} from "../PSAF/HeroElements";

import { Button } from "../../../GlobalStyles";
import Fade from "react-reveal/Fade";

const HeroContainer = styled.div`
  background: url(${bg});
  height: 100vh;
  background-position: center;
  background-size: cover;
  z-index: 1;
  margin-top: -80px;

  width: 100%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 50px;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
  }
`;

const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  padding-right: 50px;
  margin-left: 20px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  max-height: 0 2rem;
  width: 600px;
  margin-top: -20px;
  margin-left: 50px;

  font-family: "Poppins";
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 650px) {
    width: 100%;
    margin-left: 10px;
  }
`;

const Title = styled.h1`
  font-size: clamp(30px, 10vw, 50px);
  margin-bottom: 1rem;
  letter-spacing: 5px;
`;

const Paragraph = styled.p`
  font-size: clamp(16px, 1.5vw, 18px);
  margin-bottom: 2rem;
  letter-spacing: 0.06em;
  text-align: justify;
  font-weight: normal;
  line-height: 30px;
  width: 85%;

  @media screen and (max-width: 768px) {
    width: 70%;
  }
`;

function Hero({ title, subtitle }) {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <HeroContainer>
        <Container>
          <HeroContent>
            <Wrapper>
              <Fade left big cascade>
                <Title>{title}</Title>
                <Paragraph>{subtitle}</Paragraph>
                <ButtonWrapper>
                  <Button
                    href="https://cens-ui.id/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseEnter={onHover}
                    onMouseLeave={onHover}
                  >
                    VISIT WEBSITE{hover ? <Arrow /> : <ArrowRight />}
                  </Button>
                </ButtonWrapper>
              </Fade>
            </Wrapper>
          </HeroContent>
        </Container>
      </HeroContainer>
    </>
  );
}

export default Hero;
