import React from 'react';

import Hero from './HN/Hero';

import { HeroData } from './HN/Data';
import Project from './HN/Project';
import Navbar from '../Navbar/NavbarOut';

function HN() {
    return (
        <>
            <Navbar />
            <Hero {...HeroData} />
            <Project />
        </>
    )
}

export default HN
