import styled from 'styled-components';

export const ClientsSection = styled.section`
    color: #fff;
    padding: 50px 0;
    background: white;
`;

export const GridRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    @media screen and (max-width: 768px) {
        height: 1000px;
    }

    @media screen and (max-width: 480px) {
        height: 1000px;
    }
`;

export const ClientsWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: -20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 20px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-top: 20px;
    }

    @media screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

export const ClientsCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    
`;

export const Image = styled.img`
&:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
}
`;