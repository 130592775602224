import React from "react";
import icon1 from "../../Assets/imgs/cerindo.png";
import icon1Hover from "../../Assets/imgs/cerindoHover.png";
import icon2 from "../../Assets/imgs/lelang_koi.png";
import icon2Hover from "../../Assets/imgs/lelang_koiHover.png";
import icon3 from "../../Assets/imgs/hn.png";
import icon3Hover from "../../Assets/imgs/hnHover.png";
import icon4 from "../../Assets/imgs/censui.png";
import icon4Hover from "../../Assets/imgs/censuiHover.png";
import icon5 from "../../Assets/imgs/psafmipa.png";
import icon5Hover from "../../Assets/imgs/psafHover.png";
import icon6 from "../../Assets/imgs/kios.png";
import icon6Hover from "../../Assets/imgs/kiosHover.png";
import Fade from "react-reveal/Fade";

import { Row, Column, TextWrapper, Line } from "./HeroElements";

import {
  ClientsSection,
  GridRow,
  ClientsWrapper,
  ClientsCard,
  Image,
} from "./ClientsElements";

import { Container } from "../../../GlobalStyles";

const Clients = ({ lineHeading }) => {
  return (
    <>
      <ClientsSection id="/home/project">
        <Container>
          <Row>
            <Column>
              <TextWrapper>
                <Line>{lineHeading}</Line>
              </TextWrapper>
            </Column>
          </Row>
          <GridRow>
            <ClientsWrapper>
              <Fade left big cascade>
                <ClientsCard>
                  <Image
                    src={icon1}
                    onMouseOver={(e) => (e.currentTarget.src = icon1Hover)}
                    onMouseOut={(e) => (e.currentTarget.src = icon1)}
                  />
                </ClientsCard>
                <ClientsCard>
                  <Image
                    src={icon2}
                    onMouseOver={(e) => (e.currentTarget.src = icon2Hover)}
                    onMouseOut={(e) => (e.currentTarget.src = icon2)}
                  />
                </ClientsCard>

                <ClientsCard>
                  <Image
                    src={icon3}
                    onMouseOver={(e) => (e.currentTarget.src = icon3Hover)}
                    onMouseOut={(e) => (e.currentTarget.src = icon3)}
                  />
                </ClientsCard>
              </Fade>
              <Fade right big cascade>
                <ClientsCard>
                  <Image
                    src={icon4}
                    onMouseOver={(e) => (e.currentTarget.src = icon4Hover)}
                    onMouseOut={(e) => (e.currentTarget.src = icon4)}
                  />
                </ClientsCard>

                <ClientsCard>
                  <Image
                    src={icon5}
                    onMouseOver={(e) => (e.currentTarget.src = icon5Hover)}
                    onMouseOut={(e) => (e.currentTarget.src = icon5)}
                  />
                </ClientsCard>
                <ClientsCard>
                  <Image
                    src={icon6}
                    onMouseOver={(e) => (e.currentTarget.src = icon6Hover)}
                    onMouseOut={(e) => (e.currentTarget.src = icon6)}
                  />
                </ClientsCard>
              </Fade>
            </ClientsWrapper>
          </GridRow>
        </Container>
      </ClientsSection>
    </>
  );
};

export default Clients;
