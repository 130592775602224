import styled from 'styled-components';

export const FooterSection = styled.footer`
  padding: 10px 0;
  background: #10172A;
  position: relative;
`;

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-right: 50px;
  padding-left: 50px;
  align-items: center;

  @media screen and (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const FooterTextLeft = styled.div`
  display: flex;

  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;

  color: #F5F5F5;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-left: 30px;
  }
`;

export const FooterIcons = styled.div`
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  position: absolute;
  grid-gap: 15px;
  right: 0;
  top: 5px;
  transform: translate(-100%, 60%);
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0 16px;
  color: #fff;

  @media screen and (max-width: 768px) {
    display: flex;
    margin-right: -15px;
  }
`;
